import React from 'react';
import { graphql, PageProps } from 'gatsby';
import SEO from '../components/SEO';
import Terms from '../components/TermsAndConditions/Terms';

const PAGE_TITLE = 'Términos y condiciones';
const PAGE_DESCRIPTION =
  'Terminos y condiciones de los servicios de transferencias de divisas de CurrencyBird. Información esencial para usuarios.';

const PrivacyPolicy: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} />
      <Terms />
    </>
  );
};

export default PrivacyPolicy;

export const pageQuery = graphql`
  query PrivacyPolicy {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
